import React from "react";
import { useReward } from "react-rewards";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import {
  MenuList,
  MenuListItem,
  Separator,
  AppBar,
  Toolbar,
  Button,
  Frame,
  Window,
  WindowHeader,
  WindowContent,
  styleReset,
} from "react95";
// pick a theme of your choice
import original from "react95/dist/themes/original";
// original Windows95 font (optionally)
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
  
`;

const DesktopBackground = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "red",
        backgroundSize: "cover",
        zIndex: -1,
      }}
    />
  );
};

const DownloadWindow = () => {
  const { reward, isAnimating } = useReward("rewardId", "confetti");

  //   React.useEffect(() => {
  //     const interval = setInterval(() => reward(), 5000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, []);

  return (
    <Window
      style={{
        position: "absolute",
        top: 0,
        bottom: 50,
        left: 0,
        right: 0,
      }}
      className="window"
    >
      <WindowHeader className="window-title">
        <img
          src={"/icons/parrot-party.gif"}
          alt="parrot party"
          style={{ height: "20px", marginTop: 6, marginLeft: 3 }}
        />
        <span style={{ height: "20px", marginLeft: 8 }}>parrot.lol</span>
        {/* <Button>
          <span className="close-icon" />
        </Button> */}
      </WindowHeader>
      <WindowContent
        style={{
          display: "flex",
          flex: 1,
          // 33px for the window title height 2x 2px for the window border
          // 2x 16px to account for the padding
          height: "calc(100% - 33px - 4px - 32px)",
          flexDirection: "column",
        }}
      >
        <Frame
          variant="well"
          style={{
            maxWidth: "100%",
            flexGrow: 1,
            position: "relative",
          }}
        >
          <img
            src={"/icons/parrot-party.gif"}
            alt="parrot party"
            style={{
              display: "block",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              margin: "auto",
              width: "100%",
              //   height: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Frame>
        <div>
          <Button
            onClick={() => {
              window.open(
                "https://apps.apple.com/us/app/parrot-lol/id1594337193"
              );
              reward();
            }}
            size="lg"
            fullWidth={true}
            style={{ marginTop: 10, marginBottom: 0 }}
          >
            <img
              src={"/icons/apple-app-store.png"}
              alt="download from apple app store"
              style={{ height: "25px", marginRight: 5 }}
            />
            download from app store
          </Button>
          <Button
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.gravitasaeterna.smolbirbapp"
              );
              reward();
            }}
            size="lg"
            fullWidth={true}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <img
              src={"/icons/google-play.png"}
              alt="download from google play store"
              style={{ height: "25px", marginRight: 5 }}
            />
            download from play store
          </Button>
        </div>
        <span
          id="rewardId"
          style={{
            // width: 12,
            // height: 12,
            // background: "red",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      </WindowContent>
    </Window>
  );
};

const SocialButton = ({ social, link }) => {
  return (
    <Button
      onClick={() => {
        window.open(link);
      }}
      variant="flat"
      square={true}
      style={{
        height: 25,
        width: 25,
        background: "transparent",
        border: "none",
      }}
    >
      <img
        src={`/icons/${social}.png`}
        alt={`${social} logo`}
        style={{ height: "20px" }}
      />
    </Button>
  );
};

const BottomTaskBar = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 50,
        backgroundColor: "black",
        backgroundSize: "cover",
      }}
    >
      <AppBar style={{ position: "absolute", height: 50 }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <Button onClick={() => setOpen(!open)} active={open}>
              <img
                src={"/logo192.png"}
                alt="parrot logo"
                style={{ height: "20px", marginRight: 5 }}
              />
              start
            </Button>
            {open && (
              <MenuList
                style={{
                  position: "absolute",
                  left: "0",
                  bottom: "100%",
                }}
                onClick={() => setOpen(false)}
              >
                <MenuListItem
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    window.open("https://www.parrot.lol/terms.html");
                  }}
                >
                  <img
                    src={`/icons/notepad.png`}
                    alt={`notepad`}
                    style={{ height: "20px", marginRight: 5 }}
                  />
                  <text>terms</text>
                </MenuListItem>
                <MenuListItem
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    window.open("https://www.parrot.lol/privacy.html");
                  }}
                >
                  <img
                    src={`/icons/notepad.png`}
                    alt={`notepad`}
                    style={{ height: "20px", marginRight: 5 }}
                  />
                  <text>privacy</text>
                </MenuListItem>
                <MenuListItem
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    window.open("https://www.parrot.lol/acknowledgements.html");
                  }}
                >
                  <img
                    src={`/icons/notepad.png`}
                    alt={`notepad`}
                    style={{ height: "20px", marginRight: 5 }}
                  />
                  <text>thank yous</text>
                </MenuListItem>
              </MenuList>
            )}
            <Button
              onClick={() => {}}
              active={true}
              style={{
                marginLeft: 5,
              }}
            >
              <img
                src={"/icons/parrot-party.gif"}
                alt="parrot party"
                style={{ height: "20px", marginRight: 5 }}
              />
              parrot
            </Button>
          </div>

          <Frame variant="status" style={{ height: "100%" }}>
            <Toolbar style={{ padding: 0, paddingLeft: 5 }}>
              <SocialButton
                social="discord"
                link="https://discord.com/channels/964345989928468492/964348480820748338"
              />
              <SocialButton
                social="tiktok"
                link="https://www.tiktok.com/@parrot.lol"
              />
              <SocialButton
                social="twitter"
                link="https://twitter.com/parrot_lol_app"
              />
              <SocialButton
                social="instagram"
                link="https://www.instagram.com/parrot.lol"
              />
              <SocialButton social="email" link="mailto:hello@parrot.lol" />
              <div style={{ margin: 5, marginLeft: 10, marginRight: 10 }}>
                13:37
              </div>
            </Toolbar>
          </Frame>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const App = () => (
  <div>
    <GlobalStyles />
    <ThemeProvider theme={original}>
      {/* <DesktopBackground /> */}
      {/* <MenuList>
        <MenuListItem>🎤 Sing</MenuListItem>
        <MenuListItem>💃🏻 Dance</MenuListItem>
        <Separator />
        <MenuListItem disabled>😴 Sleep</MenuListItem>
      </MenuList> */}
      <DownloadWindow />
      <BottomTaskBar />
    </ThemeProvider>
  </div>
);

export default App;
